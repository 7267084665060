import { _produce, removeOne } from "../util.js";
import { formatDate } from "lib/date";
import { normalize, schema } from "normalizr";
import { setTicketLocalStorage } from "io/localStorage";
import i18n from "i18next";
export const fetchUserReservationsReducer = _produce((state) => {
	state.myReservations.loading = true;
});

// from, to, line_id
export const reservationEnqueued = _produce((state, reservation) => {
	if (reservation && reservation.oldIdReservation) {
		state.reservationsv2.queued.ids =
			state.reservationsv2.queued.ids.filter((r) => {
				return r.id !== reservation.oldIdReservation;
			});
		delete state.myReservations.byId[reservation.oldIdReservation];

		const old = state.myReservations.created.indexOf(
			reservation.oldIdReservation
		);
		if (old > -1) {
			state.myReservations.created.splice(old, 1);
		}
	}
	state.myReservations.loading = true;
	state.reservationsv2.queued.ids.push(reservation.lineSlug);
	state.reservationsv2.byId[reservation.lineSlug] = reservation;
});

export const unavailableDaysFetched = _produce((state, action) => {
	const { lineSlug, days } = action;
	state.unavailableDays.byLineId[lineSlug] = days;
	state.unavailableDays.loading = false;
});

export const unavailableDaysFetchFailed = _produce((state, action) => {
	state.unavailableDays.error = JSON.stringify(action);
});

// moves reservations from enqueued to created
// TODO: use id instead of slug in created
const reservationSchema = new schema.Entity("reservation", undefined, {
	idAttribute: "_id",
});

export const setTemporaryReservations = _produce((state, action) => {
	state.reservationsv2.queued.ids = state.reservationsv2.queued.ids.filter(
		(id) => id !== action.line.slug
	);
	state.reservationsv2.created.ids.push(action.line.slug);
	state.reservationsv2.byId[action.line.slug] = {
		...state.reservationsv2.byId[action.line.slug],
		...action,
	};
	state.reservationsv2.created.isFetching = false;
	state.reserves = state.reserves.concat(mapReservationsToV2(action));

	const { entities, result } = normalize(action, reservationSchema);
	state.myReservations.byId[result] = mapReservationsToV2(
		entities.reservation[result]
	);

	// state.myReservations.current.push(result);
	state.myReservations.created.push(result);
});
export const setLocalTemporaryReservations = _produce((state, action) => {
	if (state.user.temporal) {
		setTicketLocalStorage(action, "reservations");
	}
});

export const reservationCreatedSuccess = _produce((state, action) => {
	state.reservationsv2.queued.ids = state.reservationsv2.queued.ids.filter(
		(id) => id !== action.line.slug
	);
	state.reservationsv2.created.ids.push(action.line.slug);
	state.reservationsv2.byId[action.line.slug] = {
		...state.reservationsv2.byId[action.line.slug],
		...action,
	};
	state.reservationsv2.created.isFetching = false;
	state.reserves = state.reserves.concat(mapReservationsToV2(action));
	// This is the new, improved implementation. `state.reservationsv2`
	// should be deprecated in the future
	const { entities, result } = normalize(action, reservationSchema);
	state.myReservations.byId[result] = mapReservationsToV2(
		entities.reservation[result]
	);

	state.myReservations.created.push(result);
	state.myReservations.current.push(result);

	if (state.user.temporal) {
		state.notifications.push({
			type: "isTemporary",
			message: "is TemporaryUser",
		});
	}
	state.myReservations.loading = false;
});

export const finishReservationSaga = _produce((state) => {
	//const { reservationId } = action;
	//if (!reservationId) return;
	state.myReservations.current.pop();
	state.reservationsv2.current.pop();
	state.reservationsv2.queued.ids.pop();
});
//TODO: this is deprecated user notifyV2 instead
const notify = (state, message) => {
	state.notifications.push({
		type: "alert",
		message,
	});
};
const notifyV2 = (state, message, type = "new-alert") => {
	state.notifications.push({
		type,
		message,
	});
};

export const isSessionExpiredTempUser = _produce((state, errorMessage) => {
	if (errorMessage) {
		notify(state, errorMessage);
		return;
	}
	notify(state, "Sesion Expirada");
});

export const reservationCreatedFailure = _produce((state, errorMessage) => {
	state.myReservations.loading = false;
	if (errorMessage.errorCode === 1002) {
		notifyV2(
			state,
			"Esta hora ya ha sido tomada, por favor seleccione otra",
			"block-already-taken-error"
		);
		return;
	}
	if (errorMessage.errorCode === 1006) {
		notifyV2(state, "Solo puedes generar una reserva por oficina");
		// notify(state, "Solo puedes generar una reserva por oficina");
		return;
	}
	if (errorMessage.customCode === 4010) {
		notifyV2(
			state,
			"Disculpa los incovenientes ocasionados, por favor intenta en una pestaña de incógnito.",
			"new-alert-error"
		);

		return;
	}
	if (errorMessage.errorCode === 1005) {
		notifyV2(state, "Tienes una reserva en una de nuestras sucursales");
		// notify(state, "Solo puedes generar una reserva por oficina");
		return;
	}
	if (errorMessage.errorCode === 1007) {
		notifyV2(state, "Solo se puede obtener una reserva por fila");
		// notify(state, "Solo puedes generar una reserva por fila");
		return;
	}
	if (errorMessage.customCode === 4016) {
		notifyV2(
			state,
			"Lo sentimos, todas los cupos para esta hora fueron tomados. Por favor, inténtalo con una hora o un día diferente. ¡Gracias por tu comprensión!",
			"new-alert-error"
		);
		// notify(state, "Solo puedes generar una reserva por fila");
		return;
	}
	if (/user have/.test(errorMessage)) {
		notifyV2(state, "Ya tiene una reserva creada");
		// notify(state, "Ya tiene una reserva creada.");
		return;
	}
	notifyV2(state, i18n.t("errors:reserveCreated"), "generic-error");
});

//
//
// moves reservation from created to confirmed
export const reservationConfirmed = _produce((state, reservation) => {
	// reservationsv2 should be deprecated

	state.reservationsv2.created.ids = state.reservationsv2.created.ids.filter(
		(id) => id != reservation.lineSlug
	);
	state.reservationsv2.confirmed.ids.push(reservation.lineSlug);
	state.reservationsv2.confirmed.isFetching = false;
	// this is the current implementation
	state.myReservations.byId[reservation._id].confirmed =
		reservation.confirmed;
	state.myReservations.confirmed.push(reservation._id);
	removeOne(state.myReservations.created, reservation._id);

	const office = {};
	const date = formatDate(reservation.from, office.timezone, "YYYY-MM-DD");

	state.timeblocks.byId[`${reservation.line.slug}-${date}`].forEach(
		(tb, i) => {
			if (tb.from.getTime() === new Date(reservation.from).getTime())
				state.timeblocks.byId[`${reservation.line.slug}-${date}`][i]
					.availables--;
		}
	);
});

export const clearReservationTimeouts = _produce((state, reservation) => {
	state.reservationsv2.timeouts
		.reduce(
			(p, c) => (c.lineId === reservation.lineSlug ? p.concat(c) : p),
			[]
		)
		.forEach((timeout) => clearTimeout(timeout.timeout));
	state.reservationsv2.timeouts = state.reservationsv2.timeouts.filter(
		(to) => to.lineId !== reservation.lineSlug
	);
});

// this is a hack. this should clear only the timeouts for the reservation's scope
export const clearAllReservationTimeouts = _produce((state) => {
	state.reservationsv2.timeouts.forEach((timeout) =>
		clearTimeout(timeout.timeout)
	);
});

export const reservationTimeoutSet = _produce((state, action) => {
	state.reservationsv2.timeouts.push(action);
});

export const reservationTimeoutFinished = _produce((state) => {
	state.notifications.push({
		type: "alert",
		message: "Se ha superado el tiempo de espera para crear una reserva",
	});
	// TODO: delete reservation even if it is confirmed
});

export const timeBlocksReset = _produce((state) => {
	state.timeblocks.errorMessage = "";
	state.timeblocks.error = {};
});

export const timeBlocksRequested = _produce((state, action) => {
	state.timeblocks.isLoading = true;
	state.timeblocks.errorMessage = "";
	state.timeblocks.error = {};
	//state.timeblocks.byId[action.lineSlug] = [];
});

//if (!state.timeblocks.byId[lineSlug]) state.timeblocks.byId[lineSlug] = {};
//state.timeblocks.byId[lineSlug][date] = action.tb;
export const timeBlocksGotten = _produce((state, action) => {
	const { lineSlug, tzDate, tb } = action;
	state.timeblocks.isLoading = false;
	state.timeblocks.byId[lineSlug] = tb
		.map(parseDate(tzDate))
		.filter((d) => d.from.getTime() > new Date().getTime());

	state.timeblocks.error = {};
	state.timeblocks.byId[`${lineSlug}-${tzDate}`] = tb
		.map(parseDate(tzDate))
		.filter((d) => d.from.getTime() > new Date().getTime());
});

export const timeBlocksRetrievalFailure = _produce((state, action) => {
	/*
	if (/create office/.test(action.data.message)) {
		state.notifications.push({
			type: "alert",
			message: "No hay bloques horarios configurados para este día"
		});
		return;
	}
	state.notifications.push({
		type: "alert",
		message: "Ha ocurrido un error al obtener los bloques horarios."
	});
	*/
	state.timeblocks.isLoading = false;
	state.timeblocks.errorMessage =
		"Ha ocurrido un error al obtener los bloques horarios.";
	state.timeblocks.error.message =
		"Ha ocurrido un error al obtener los bloques horarios.";
	state.timeblocks.error.debug = action;
});

const parseDate = (tzDate) => (tb) => {
	if (tb.from.length > 8)
		return {
			...tb,
			from: new Date(tb.from),
			to: new Date(tb.to),
		};
	return {
		...tb,
		from: new Date(`${tzDate}T${tb.from}Z`),
		to: new Date(`${tzDate}T${tb.to}Z`),
	};
};

/*
 * office(pin): "servipag-espacio-urbano-centro-vina"
 * line(pin): "servipag-espacio-urbano-centro-vina-atencion-general"
 * lineName(pin): "Atención General"
 * user_id(pin): 133789
 * to(pin): "2020-08-24T16:30:00.000Z"
 * line_id(pin): 296
 * id(pin): 129274
 * from(pin): "2020-08-24T16:15:00.000Z"
 * confirmed(pin): true
 */

// WARN: maps reservations FROM v2 TO v1
export const mapReservationsToV2 = (v2) => ({
	...v2,
	office: v2.office.slug,
	line: v2.line.slug,
	line_id: v2.line.id,
	lineName: v2.line.name,
	officeName: v2.office.name,
	user_id: v2.user.id,
	from: v2.from,
	to: v2.to,
	id: v2._id,
	confirmed: v2.confirmed,
	code: "RV" + String(v2._id).trim().substr(-3),
	isV2: true,
});

export const reservationsFetchedReducer = _produce((state, action) => {
	state.reserves = state.reserves.concat(action);
});

export const reservationsFormsPayload = _produce((state, action) => {
	state.forms.payload = action.payload;
});
export const reservationsFetchedIsGoingOn = _produce((state, action) => {
	state.forms.loading = true;
});
export const reservationFormsFetchedReducer = _produce((state, action) => {
	state.forms.reservationForms = action;
	state.forms.fields = action.questions;
	state.forms.error = null;
	state.forms.loading = false;
});
export const ticketsFormsFetchedReducer = _produce((state, action) => {
	state.forms.ticketForms = action;
	state.forms.ticketFields = action.questions;
	state.forms.error = null;
	state.forms.loading = false;
});
export const reservationFormsFetchedReducerFailure = _produce(
	(state, action) => {
		state.forms.reservationForms = [];
		state.forms.fields = [];
		state.forms.error = "Hubo un error al traer los forms";
		state.forms.loading = false;
	}
);
export const ticketFormsFetchedReducerFailure = _produce((state, action) => {
	state.forms.ticketForms = {};
	state.forms.ticketFields = [];
	state.forms.error = "Hubo un error al traer los forms";
	state.forms.loading = false;
});

const reservationsSchema = [new schema.Entity("reservations")];
export const addMyReservationsV1ToState = _produce((state, reservations) => {
	const { entities, result } = normalize(reservations, reservationsSchema);
	state.myReservations.byId = {
		...state.myReservations.byId,
		...entities.reservations,
	};
	state.myReservations.created = state.myReservations.created.concat(result);
	state.myReservations.loading = false;
});

export const reservationsFetchFailed = _produce((state, action) => {
	state.myReservations.errorMessage = {
		message: "Error al obtener las reservas",
		debug: action,
	};
	state.myReservations.loading = false;
});

export const reservationDeleted = _produce((state, action) => {
	const { reservationId, lineSlug } = action;
	state.reservationsv2.byId[lineSlug] = {
		...state.reservationsv2.byId[lineSlug],
		deleted: true,
	};
	state.reserves = state.reserves.map((r) => {
		if (r.id === reservationId)
			return {
				...r,
				deleted: true,
			};
		return r;
	});
	state.myReservations.byId[reservationId].deleted = true;
	// state.notifications.push({
	// 	type: "success",
	// 	message: "Reserva eliminada correctamente",
	// });
	state.myReservations.loading = false;
});

export const reservationDeletionFailed = _produce((state) => {
	notify(state, "Hubo un error al eliminar la reserva.");
	return undefined;
});

export const changeSocketState = _produce((state, action) => {
	state.socketio = action;
});

export const handleReservationById = _produce((state, action) => {
	state.myReservations.respald = action;
});

export const setDataTimeBlocksV3 = _produce((state, action) => {
	state.reservationsv3.dataTimeblocks = action;
});

export const setOldIdReservation = _produce((state, action) => {
	state.reservationsv3.oldIdReservation = action;
});
